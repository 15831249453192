<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "../../components/Loader.svelte";
  import { getData } from "../../utils/ApiUtils.svelte";
  import { onMount } from "svelte";
  import {
    dynamicSort,
    average,
    formatNumberWith4Colors,
  } from "../../utils/FormatUtils.svelte";

  let courseList = [];

  let cMap = new Map();
  let cMap2 = new Map();
  let lMap = new Map();
  let eMap = new Map();
  let cGroups = {};
  let eGroups = {};

  let dataStatus = "new";

  let courseStatus = "loading";

  onMount(async () => {
    getCourseList();
    getStudentAccountReport();
  });

  function getCourseList() {
    getData("studentapi/courses/projection")
      .then((data) => {
        console.log("course successful", data);

        courseList = data;
        courseStatus = "ok";
      })
      .catch((error) => {
        courseStatus = "error";
        console.log("error", error);
      });
  }

  function getStudentAccountReport() {
    getData(`studentapi/report`)
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        data.forEach((e) => {
          cMap.set(e.idCourse, e.courseName);
          lMap.set(e.idLesson, e.lessonName);
          eMap.set(e.idExercise, e.title);
        });

        [...cMap.keys()].forEach((k) => {
          let obj = data
            .filter((v) => v.idCourse === k)
            .reduce((r, a) => {
              const k = a.idLesson; //{ idCourse: a.idCourse, courseName: a.courseName };
              r[k] = r[k] || [];
              r[k].push(a);
              return r;
            }, Object.create(null));
          let avgScoreAllEx = average(
            data
              .filter((v1) => v1.idCourse === k && v1?.maxScore !== null)
              .map((v2) => v2?.maxScore),
          );

          let lessonMap2 = new Map();

          Object.entries(obj).forEach((v) => {
            let avgScoreLesson = getScore(v[1]);
            let lessonDetail = {
              lessonData: [...v[1]],
              avgScoreLesson: avgScoreLesson,
            };
            lessonMap2.set(v[0], lessonDetail);
          });

          console.log("xxx", lessonMap2);

          let lessonMap = new Map(Object.entries(obj));

          cMap2.set(k, {
            courseName: cMap.get(k),
            lesson: lessonMap2,
            avgScoreAllEx: avgScoreAllEx ? avgScoreAllEx : "",
          });
        });

        cGroups = data.reduce((r, a) => {
          const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        eGroups = data.reduce((r, a) => {
          const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        console.log("x", cMap, cMap2, lMap, eMap, cGroups, eGroups);

        dataStatus = "ok";
      })
      .catch((error) => {
        dataStatus = "error";
        console.log("error report", error);
      });
  }

  function getScore(l) {
    let avgScoreLessonEx = average(
      l.filter((v1) => v1?.maxScore).map((v2) => v2?.maxScore !== null),
    );
    return avgScoreLessonEx;
  }

  function classBasedOnScore(score) {
    if (score >= 90) {
      return "excellent";
    } else if (score >= 80) {
      return "good";
    } else if (score >= 60) {
      return "poor";
    }
    return "bad";
  }

  function viewCourseDetail(id) {
    mixpanel.track("Student Opens Class", {
      classID: id,
    });
    console.log("viewCourseDetail", id);
    router.goto("/course/" + id);
  }

  function classStyleBasedOnData(c) {
    if (c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount) {
      return "header-done";
    } else if (
      c.exerciseDoneCount > 0 &&
      c.lessonWithRecordingCount < c.exerciseCount
    ) {
      return "header-in-progress";
    } else if (c.exerciseCount === 0) {
      return ["header-new"].join(" ");
    }
    return [].join(" ");
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1>{$_("COURSE_LIST")}</h1>
    <!-- <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">{$_("COURSE_LIST")}</li>
    </ol> -->
    <div class="row">
      {#if courseStatus === "loading"}
        <Loader />
      {:else if courseStatus === "error"}
        {$_("ERROR_LOADING_LESSON_LIST")}
      {:else if dataStatus === "ok" && courseList && courseList.length > 0}
        {#if courseList.filter((c) => c.exerciseDoneCount === 0)?.length > 0}
          <h3 class="mt-4">{$_("COURSE.NEW")}</h3>
          <hr />

          {#each courseList
            .filter((c) => c.exerciseDoneCount === 0)
            .sort(dynamicSort("courseName")) as c, i}
            <div class="col-xl-3 col-md-6">
              <div
                class="card mb-4"
                on:click={() => {
                  viewCourseDetail(c.courseId);
                }}
              >
                <div class={"card-header " + classStyleBasedOnData(c)}>
                  {#if c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount}
                    <i class="fas fa-star" style="color: #ffcc07ff" />
                  {:else if c.exerciseDoneCount > 0 && c.exerciseDoneCount < c.exerciseCount}
                    <i class="fas fa-star-half-alt" />
                  {:else}
                    <i class="far fa-star" />
                  {/if}

                  <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
                </div>
                <div class="card-body course-card-body">{c.courseName}</div>
              </div>
            </div>
          {/each}
        {/if}

        {#if courseList.filter((c) => c.exerciseDoneCount > 0 && c.exerciseDoneCount < c.exerciseCount)?.length > 0}
          <h3>{$_("COURSE.IN_PROGRESS")}</h3>
          <hr />
          {#each courseList
            .filter((c) => c.exerciseDoneCount > 0 && c.exerciseDoneCount < c.exerciseCount)
            .sort(dynamicSort("courseName")) as c, i}
            <div class="col-xl-3 col-md-6">
              <div
                class="card mb-4"
                on:click={() => {
                  viewCourseDetail(c.courseId);
                }}
              >
                <div class={"card-header " + classStyleBasedOnData(c)}>
                  <div
                    class="mt-1"
                    style="display: flex; justify-content:space-between"
                  >
                    {#if c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount}
                      <i class="fas fa-star" style="color: #ffcc07ff" />
                    {:else if c.exerciseDoneCount > 0 && c.exerciseDoneCount < c.exerciseCount}
                      <i class="fas fa-star-half-alt" />
                    {:else}
                      <i class="far fa-star" />
                    {/if}

                    <span class="badge bg-secondary"
                      >{formatNumberWith4Colors(
                        cMap2.get(c.courseId)?.avgScoreAllEx,
                      )}</span
                    >
                    <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
                  </div>
                </div>
                <div class="card-body course-card-body">{c.courseName}</div>
              </div>
            </div>
          {/each}
        {/if}

        {#if courseList.filter((c) => c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount)?.length > 0}
          <h3>{$_("COURSE.DONE")}</h3>
          <hr />
          {#each courseList
            .filter((c) => c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount)
            .sort(dynamicSort("courseName")) as c, i}
            <div class="col-xl-3 col-md-6">
              <div
                class="card mb-4"
                on:click={() => {
                  viewCourseDetail(c.courseId);
                }}
              >
                <div class={"card-header " + classStyleBasedOnData(c)}>
                  <div
                    class="mt-1"
                    style="display: flex; justify-content:space-between"
                  >
                    {#if c.exerciseDoneCount > 0 && c.exerciseDoneCount === c.exerciseCount}
                      <i class="fas fa-star" style="color: #ffcc07ff" />
                    {:else if c.exerciseDoneCount > 0 && c.exerciseDoneCount < c.exerciseCount}
                      <i class="fas fa-star-half-alt" />
                    {:else}
                      <i class="far fa-star" />
                    {/if}

                    <span class="badge bg-secondary"
                      >{formatNumberWith4Colors(
                        cMap2.get(c.courseId)?.avgScoreAllEx,
                      )}</span
                    >
                    <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
                  </div>
                </div>
                <div class="card-body course-card-body">{c.courseName}</div>
              </div>
            </div>
          {/each}
        {/if}
      {:else}
        <div class="col align-self-center">
          <div class="text-center">
            <svg
              width="201"
              height="200"
              viewBox="0 0 201 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M102.5 89.3701C99.7311 89.3701 97.0243 88.549 94.722 87.0107C92.4197 85.4724 90.6253 83.2859 89.5657 80.7277C88.5061 78.1695 88.2288 75.3546 88.769 72.6389C89.3092 69.9231 90.6426 67.4286 92.6005 65.4706C94.5584 63.5127 97.053 62.1793 99.7687 61.6391C102.484 61.0989 105.299 61.3762 107.858 62.4358C110.416 63.4954 112.602 65.2899 114.141 67.5921C115.679 69.8944 116.5 72.6012 116.5 75.3701C116.484 79.0783 115.004 82.6301 112.382 85.2522C109.76 87.8743 106.208 89.3543 102.5 89.3701Z"
                fill="#DCDCDC"
              />
              <path
                d="M119.59 115.08L151.95 74.8998L139.36 64.8198L115.02 93.9998H85.39C82.5024 94.013 79.7371 95.1673 77.6971 97.2111C75.6571 99.2548 74.5079 102.022 74.5 104.91V117.34H119.58L119.59 115.08Z"
                fill="#DCDCDC"
              />
              <path
                d="M103.61 87.5698C100.841 87.5698 98.1347 86.7487 95.8324 85.2104C93.5301 83.6721 91.7357 81.4856 90.676 78.9274C89.6164 76.3692 89.3392 73.5543 89.8794 70.8386C90.4196 68.1228 91.7529 65.6283 93.7109 63.6703C95.6688 61.7124 98.1634 60.379 100.879 59.8388C103.595 59.2986 106.41 59.5759 108.968 60.6355C111.526 61.6951 113.713 63.4896 115.251 65.7918C116.789 68.0941 117.61 70.8009 117.61 73.5698C117.595 77.278 116.115 80.8298 113.492 83.4519C110.87 86.074 107.319 87.554 103.61 87.5698ZM103.61 65.7998C102.071 65.7979 100.566 66.2525 99.2853 67.1061C98.0046 67.9598 97.0058 69.1742 96.4155 70.5956C95.8251 72.0171 95.6696 73.5817 95.9686 75.0915C96.2677 76.6013 97.0079 77.9885 98.0955 79.0776C99.1832 80.1666 100.569 80.9086 102.079 81.2096C103.588 81.5106 105.153 81.3571 106.575 80.7686C107.998 80.18 109.213 79.1828 110.068 77.9032C110.924 76.6236 111.38 75.119 111.38 73.5798C111.372 71.5206 110.552 69.5478 109.096 68.0908C107.641 66.6337 105.67 65.8104 103.61 65.7998Z"
                fill="#707070"
              />
              <path
                d="M120.71 113.27L153.07 73.09L140.5 63L116.14 92.23H86.4996C83.6181 92.2432 80.8583 93.3937 78.8208 95.4312C76.7832 97.4688 75.6327 100.228 75.6196 103.11V115.54H66.3096V121.76H72.4996V152.85H78.7196V121.76H122.24V152.85H128.5V121.76H134.72V115.54H120.72L120.71 113.27ZM92.7096 115.54V107.77H86.4996V115.54H81.8496V103.11C81.8548 101.877 82.3461 100.697 83.2167 99.8242C84.0873 98.9517 85.267 98.4579 86.4996 98.45H119.05L141.34 71.69L144.34 74.06L114.53 111.06V115.52L92.7096 115.54Z"
                fill="#707070"
              />
              <path
                d="M164.23 121.76H150.23V115.54H158V53.3699H42.9995V115.54H50.7695V121.76H36.7695V47.1499H164.23V121.76Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M53.8701 64.25H75.6301V70.47H53.8701V64.25Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M53.8701 79.79H67.8701V86H53.8701V79.79Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M53.8701 95.3398H67.8701V101.55H53.8701V95.3398Z"
                fill="#707070"
              />
            </svg>

            <h3>{$_("NO_CLASS")}</h3>
          </div>
        </div>
      {/if}
    </div>
  </div>
</main>

<style>
  .card {
    cursor: pointer;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  }

  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .course-card-body {
    min-height: 5.5rem;
  }
</style>
