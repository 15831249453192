<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "../../components/Loader.svelte";
  import { getData } from "../../utils/ApiUtils.svelte";
  import { onMount } from "svelte";
  import CourseLastXDaysRanking from "./CourseLastXDaysRanking.svelte";
  import { userConfig } from "../../components/DataStore";
  import {
    dynamicSort,
    average,
    formatNumberWith4Colors,
  } from "../../utils/FormatUtils.svelte";

  export let courseId;

  let cMap = new Map();
  let cMap2 = new Map();
  let lMap = new Map();
  let eMap = new Map();
  let cGroups = {};
  let eGroups = {};
  let dataStatus = "loading";

  let courseDetail;
  let courseStatus = "loading";
  let lessonsList = [];
  let lessonStatus = "loading";

  onMount(async () => {
    getCourseById(courseId);
    getLessonByCourseId(courseId);
    getStudentAccountReport(courseId);
  });

  function getCourseById(courseId) {
    getData("studentapi/courses2/" + courseId)
      .then((data) => {
        console.log("course successful", data);

        courseDetail = data;
        courseStatus = "ok";
      })
      .catch((error) => {
        courseStatus = "error";
        console.log("error", error);
      });
  }

  function checkActiveState(start, end) {
    let now = new Date();
    let startDate = new Date(Date.parse(start));
    let endDate = new Date(Date.parse(end));

    if (startDate > now) {
      return "TOO_EARLY";
    }
    if (now > endDate) {
      return "TOO_LATE";
    }
    return "OK";
  }

  function getLessonByCourseId(courseId) {
    getData("studentapi/courses/" + courseId + "/lessons/projection")
      .then((data) => {
        console.log("lessons successful", data);

        data = data.map((l) => {
          l["activeState"] = checkActiveState(
            l.activeDateStart,
            l.activeDateEnd,
          );
          return l;
        });

        lessonsList = data.sort(dynamicSort("activeState"));
        lessonStatus = "ok";
      })
      .catch((error) => {
        lessonStatus = "error";
        console.log("error", error);
      });
  }

  function getStudentAccountReport(courseId) {
    getData(`studentapi/report?courseId=${courseId}`)
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        data.forEach((e) => {
          cMap.set(e.idCourse, e.courseName);
          lMap.set(e.idLesson, e.lessonName);
          eMap.set(e.idExercise, e.title);
        });

        [...cMap.keys()].forEach((k) => {
          let obj = data
            .filter((v) => v.idCourse === k)
            .reduce((r, a) => {
              const k = a.idLesson; //{ idCourse: a.idCourse, courseName: a.courseName };
              r[k] = r[k] || [];
              r[k].push(a);
              return r;
            }, Object.create(null));
          let avgScoreAllEx = average(
            data
              .filter((v1) => v1.idCourse === k && v1?.maxScore !== null)
              .map((v2) => v2?.maxScore),
          );

          let lessonMap2 = new Map();

          Object.entries(obj).forEach((v) => {
            let avgScoreLesson = getScore(v[1]);
            let lessonDetail = {
              lessonData: [...v[1]],
              avgScoreLesson: avgScoreLesson,
            };
            lessonMap2.set(parseInt(v[0]), lessonDetail);
          });

          console.log("xxx", lessonMap2);

          let lessonMap = new Map(Object.entries(obj));

          cMap2.set(k, {
            courseName: cMap.get(k),
            lesson: lessonMap2,
            avgScoreAllEx: avgScoreAllEx ? avgScoreAllEx : "",
          });
        });

        cGroups = data.reduce((r, a) => {
          const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        eGroups = data.reduce((r, a) => {
          const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        console.log("x", cMap, cMap2, lMap, eMap, cGroups, eGroups);

        dataStatus = "ok";
      })
      .catch((error) => {
        dataStatus = "error";
        console.log("error report", error);
      });
  }

  function getScore(l) {
    let avgScoreLessonEx = average(
      l.filter((v1) => v1?.maxScore !== null).map((v2) => v2?.maxScore),
    );
    return avgScoreLessonEx;
  }

  function viewLessonDetail(id) {
    mixpanel.track("Student Opens Lesson", { lessonID: id });
    console.log("viewLessonDetail", id);
    router.goto("/lesson/" + id);
  }

  function calculateLessonColor(done, count) {
    if (done === count) {
      return "header-done";
    } else if (done < count && done > 0) {
      return "header-in-progress";
    } else {
      return "header-new";
    }
  }

  function getLessonScore(lessonId) {
    return cMap2?.get(parseInt(courseId))?.lesson?.get(lessonId)
      ?.avgScoreLesson;
  }
</script>

<main>
  <div class="container-fluid px-4">
    <ol class="breadcrumb mt-4 mb-4">
      <li
        class="breadcrumb-item"
        style="cursor:pointer"
        on:click={() => {
          router.goto("/course-list");
        }}
      >
        {$_("COURSE_LIST")}
      </li>
      {#if courseDetail?.courseName}<li class="breadcrumb-item active">
          {courseDetail?.courseName}
        </li>{/if}
    </ol>
    {#if courseStatus === "loading" || lessonStatus === "loading" || dataStatus === "loading"}
      <Loader />
    {:else}
      <div class="row">
        {#if courseStatus === "loading"}
          <Loader />
        {:else if courseStatus === "error"}
          {$_("ERROR_LOADING_COURSE")}
        {:else if courseDetail}
          <h2>{courseDetail.courseName}</h2>
          <p>{courseDetail.courseDescription}</p>
          {#if courseDetail?.organizationName}<p>
              {$_("ORGANIZATION_NAME")}
              {courseDetail?.organizationName}
            </p>
          {/if}
          <p>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.16657 10.9834V13.3251C4.16657 13.9334 4.4999 14.5001 5.03324 14.7917L9.1999 17.0667C9.6999 17.3417 10.2999 17.3417 10.7999 17.0667L14.9666 14.7917C15.4999 14.5001 15.8332 13.9334 15.8332 13.3251V10.9834L10.7999 13.7334C10.2999 14.0084 9.6999 14.0084 9.1999 13.7334L4.16657 10.9834ZM9.1999 2.9334L2.1749 6.76673C1.5999 7.0834 1.5999 7.91673 2.1749 8.2334L9.1999 12.0667C9.6999 12.3417 10.2999 12.3417 10.7999 12.0667L17.4999 8.4084V13.3334C17.4999 13.7917 17.8749 14.1667 18.3332 14.1667C18.7916 14.1667 19.1666 13.7917 19.1666 13.3334V7.99173C19.1666 7.6834 18.9999 7.4084 18.7332 7.2584L10.7999 2.9334C10.2999 2.66673 9.6999 2.66673 9.1999 2.9334Z"
                fill="#222529"
              />
            </svg>
            {courseDetail?.teacherName}
          </p>
          <p>
            {$_("OVERALL_SCORE")}: {formatNumberWith4Colors(
              cMap2?.get(parseInt(courseId))?.avgScoreAllEx,
            )}
          </p>

          <!-- <p>{$_('SCORING_TYPE')}: {$_(courseDetail?.scoringType)}</p> -->
          <!-- <li>{$_("REPORT_TYPE")}: {$_(courseDetail?.reportType)}</li> -->
        {/if}
      </div>
      {#if $userConfig?.serverFlags?.showClassRanking === true}
        <CourseLastXDaysRanking {courseId} />
      {/if}

      <div class="row">
        {#if lessonStatus === "loading"}
          <Loader />
        {:else if lessonStatus === "error"}
          {$_("ERROR_LOADING_LESSON_LIST")}
        {:else}
          {#if lessonsList
            .filter((l) => l?.activeState === "OK")
            .filter((l) => l.exerciseCountDone === 0).length > 0}
            <h3 class="mt-4">{$_("LESSON.NEW")}</h3>
            <hr />

            {#each lessonsList
              .filter((l) => l?.activeState === "OK")
              .filter((l) => l.exerciseCountDone === 0) as l, i}
              {#if l.activeState === "OK"}
                <div class="col-xl-3 col-md-6">
                  <div
                    class="card mb-4"
                    on:click={() => {
                      viewLessonDetail(l.lessonId);
                    }}
                  >
                    <div
                      class="card-header"
                      class:header-done={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone === l.exerciseCount}
                      class:header-in-progress={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone < l.exerciseCount}
                      class:header-new={l.exerciseCountDone === 0}
                    >
                      <div
                        class="mt-1"
                        style="display: flex; justify-content:space-between"
                      >
                        <span
                          >{#if l.exerciseCountDone > 0 && l.exerciseCountDone === l.exerciseCount}
                            <i class="fas fa-star" style="color: #ffcc07ff" />
                          {:else if l.exerciseCountDone > 0 && l.exerciseCountDone < l.exerciseCount}
                            <i class="fas fa-star-half-alt" />
                          {:else}
                            <i class="far fa-star" />
                          {/if}

                          {l.exerciseCountDone}/{l.exerciseCount}
                        </span>
                        <span class="badge bg-secondary"
                          >{formatNumberWith4Colors(
                            getLessonScore(l.lessonId),
                          )}</span
                        >
                      </div>
                    </div>
                    <div
                      class="card-body lesson-card-body"
                      data-state={l?.activeState}
                    >
                      <div>{l.lessonName}</div>
                      <div class="mt-2">
                        {#if l.activeState === "TOO_EARLY" && l?.activeDateStart}
                          <span class="available-label"
                            >{$_("AVAILABLE_FROM")}
                            {new Date(
                              Date.parse(l?.activeDateStart),
                            ).toLocaleString()}</span
                          >
                        {/if}
                        {#if l?.activeDateEnd}
                          <span class="available-label"
                            >{$_("AVAILABLE_UNTIL")}
                            {new Date(
                              Date.parse(l?.activeDateEnd),
                            ).toLocaleString()}</span
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}

            {#each lessonsList
              .sort(dynamicSort("activeDateStart"))
              .filter((l) => l?.activeState === "TOO_EARLY")
              .slice(0, 1) as l, i}
              {#if l.activeState === "TOO_EARLY"}
                <div class="col-xl-3 col-md-6">
                  <div class="card mb-4" style="cursor: not-allowed;">
                    <div
                      class="card-header not-yet"
                      class:header-done={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone === l.exerciseCount}
                      class:header-in-progress={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone < l.exerciseCount}
                      class:header-new={l.exerciseCountDone === 0}
                    >
                      <div
                        class="mt-1"
                        style="display: flex; justify-content:space-between"
                      >
                        <span>
                          <i class="far fa-bell" />
                          {$_("NEXT_LESSON")}
                        </span>
                        <span class="badge bg-secondary"
                          >{formatNumberWith4Colors(
                            getLessonScore(l.lessonId),
                          )}</span
                        >
                      </div>
                    </div>
                    <div
                      class="card-body lesson-card-body"
                      data-state={l?.activeState}
                    >
                      <div>{l.lessonName}</div>
                      {#if l.activeState === "TOO_EARLY" && l?.activeDateStart}
                        <div class="mt-2">
                          <span class="available-label"
                            >{$_("AVAILABLE_FROM")}
                            {new Date(
                              Date.parse(l?.activeDateStart),
                            ).toLocaleString()}</span
                          >
                        </div>
                      {/if}
                      <div class="mt-2">
                        {#if l?.activeDateEnd}
                          <span class="available-label"
                            >{$_("AVAILABLE_UNTIL")}
                            {new Date(
                              Date.parse(l?.activeDateEnd),
                            ).toLocaleString()}</span
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}
          {/if}

          {#if lessonsList
            .filter((l) => l?.activeState === "OK")
            .filter((l) => l.exerciseCountDone > 0 && l.exerciseCountDone < l.exerciseCount).length > 0}
            <h3 class="mt-4">{$_("LESSON.IN_PROGRESS")}</h3>
            <hr />

            {#each lessonsList.filter((l) => l.exerciseCountDone > 0 && l.exerciseCountDone < l.exerciseCount) as l, i}
              {#if l.activeState === "OK"}
                <div class="col-xl-3 col-md-6">
                  <div
                    class="card mb-4"
                    on:click={() => {
                      viewLessonDetail(l.lessonId);
                    }}
                  >
                    <div
                      class="card-header"
                      class:header-done={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone === l.exerciseCount}
                      class:header-in-progress={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone < l.exerciseCount}
                      class:header-new={l.exerciseCountDone === 0}
                    >
                      <div
                        class="mt-1"
                        style="display: flex; justify-content:space-between"
                      >
                        <span
                          >{#if l.exerciseCountDone > 0 && l.exerciseCountDone === l.exerciseCount}
                            <i class="fas fa-star" style="color: #ffcc07ff" />
                          {:else if l.exerciseCountDone > 0 && l.exerciseCountDone < l.exerciseCount}
                            <i class="fas fa-star-half-alt" />
                          {:else}
                            <i class="far fa-star" />
                          {/if}

                          {l.exerciseCountDone}/{l.exerciseCount}
                        </span>
                        <span class="badge bg-secondary"
                          >{formatNumberWith4Colors(
                            getLessonScore(l.lessonId),
                          )}</span
                        >
                      </div>
                    </div>
                    <div
                      class="card-body lesson-card-body"
                      data-state={l?.activeState}
                    >
                      <div>{l.lessonName}</div>
                      <div class="mt-2">
                        {#if l.activeState === "TOO_EARLY" && l?.activeDateStart}
                          <span class="available-label"
                            >{$_("AVAILABLE_FROM")}
                            {new Date(
                              Date.parse(l?.activeDateStart),
                            ).toLocaleString()}</span
                          >
                        {/if}
                        {#if l?.activeDateEnd}
                          <span class="available-label"
                            >{$_("AVAILABLE_UNTIL")}
                            {new Date(
                              Date.parse(l?.activeDateEnd),
                            ).toLocaleString()}</span
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}
          {/if}

          {#if lessonsList
            .filter((l) => l?.activeState === "OK")
            .filter((l) => l.exerciseCountDone > 0 && l.exerciseCountDone === l.exerciseCount).length > 0}
            <h3 class="mt-4">{$_("LESSON.DONE")}</h3>
            <hr />

            {#each lessonsList
              .filter((l) => l?.activeState === "OK")
              .filter((l) => l.exerciseCountDone > 0 && l.exerciseCountDone === l.exerciseCount) as l, i}
              {#if l.activeState === "OK"}
                <div class="col-xl-3 col-md-6">
                  <div
                    class="card mb-4"
                    on:click={() => {
                      viewLessonDetail(l.lessonId);
                    }}
                  >
                    <div
                      class="card-header"
                      class:header-done={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone === l.exerciseCount}
                      class:header-in-progress={l.exerciseCountDone > 0 &&
                        l.exerciseCountDone < l.exerciseCount}
                      class:header-new={l.exerciseCountDone === 0}
                    >
                      <div
                        class="mt-1"
                        style="display: flex; justify-content:space-between"
                      >
                        <span
                          >{#if l.exerciseCountDone > 0 && l.exerciseCountDone === l.exerciseCount}
                            <i class="fas fa-star" style="color: #ffcc07ff" />
                          {:else if l.exerciseCountDone > 0 && l.exerciseCountDone < l.exerciseCount}
                            <i class="fas fa-star-half-alt" />
                          {:else}
                            <i class="far fa-star" />
                          {/if}

                          {l.exerciseCountDone}/{l.exerciseCount}
                        </span>
                        <span class="badge bg-secondary"
                          >{formatNumberWith4Colors(
                            getLessonScore(l.lessonId),
                          )}</span
                        >
                      </div>
                    </div>
                    <div
                      class="card-body lesson-card-body"
                      data-state={l?.activeState}
                    >
                      <div>{l.lessonName}</div>
                      <div class="mt-2">
                        {#if l.activeState === "TOO_EARLY" && l?.activeDateStart}
                          <span class="available-label"
                            >{$_("AVAILABLE_FROM")}
                            {new Date(
                              Date.parse(l?.activeDateStart),
                            ).toLocaleString()}</span
                          >
                        {/if}
                        {#if l?.activeDateEnd}
                          <span class="available-label"
                            >{$_("AVAILABLE_UNTIL")}
                            {new Date(
                              Date.parse(l?.activeDateEnd),
                            ).toLocaleString()}</span
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}
          {/if}

          {#if lessonsList?.filter((l) => l?.activeState === "OK").length === 0}
            <div class="row" style="padding-left:15%; padding-right:15%;">
              <svg
                class="text-center"
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M72.8301 138.19V39.19C70.8301 35.19 54.8301 37.19 54.8301 44.19L54.8901 150.36C55.4598 152.274 56.6313 153.954 58.2308 155.15C59.8304 156.346 61.7728 156.995 63.7701 157H143.17V139C132.59 140.21 83.2401 138.14 72.8301 138.19Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M72.8196 37.1904H65.8196C63.366 37.2036 61.0168 38.1848 59.2828 39.9207C57.5487 41.6566 56.5701 44.0068 56.5596 46.4604L56.9396 148.36C57.5068 150.267 58.6708 151.94 60.2603 153.136C61.8498 154.331 63.7809 154.985 65.7696 155H71.0596V149.71H65.7696C65.2072 149.78 64.6366 149.729 64.0955 149.561C63.5544 149.393 63.0551 149.112 62.6307 148.737C62.2063 148.361 61.8664 147.9 61.6337 147.384C61.4009 146.867 61.2806 146.307 61.2806 145.74C61.2806 145.174 61.4009 144.614 61.6337 144.097C61.8664 143.581 62.2063 143.119 62.6307 142.744C63.0551 142.369 63.5544 142.088 64.0955 141.92C64.6366 141.752 65.2072 141.701 65.7696 141.77H139.88V149.71H78.9996V155H145.17V37.1904H72.8196ZM65.8196 136.45C64.4344 136.446 63.0665 136.757 61.8196 137.36V46.4604C61.8196 45.3996 62.241 44.3821 62.9911 43.632C63.7413 42.8819 64.7587 42.4604 65.8196 42.4604H67.5796V125.83H72.8696V42.4904H139.93V136.49L65.8196 136.45Z"
                  fill="#707070"
                />
                <path
                  d="M84.3002 162.85L75.0002 159.14L65.7402 162.85V144.39H71.0302V155L75.0302 153.41L79.0002 155V144.39H84.3002V162.85Z"
                  fill="#707070"
                />
              </svg>

              <h3 class="text-center">
                {$_("LESSON_TABLE_EMPTY_PROMPT_EMPTY")}
              </h3>
            </div>
          {/if}
        {/if}
      </div>
    {/if}
  </div>
</main>

<style>
  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .lesson-card-body {
    min-height: 6rem;
  }

  .ok {
    background-color: #c6e48b;
  }

  .card {
    cursor: pointer;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  }

  .available-label {
    font-size: smaller;
  }

  .not-yet {
    background-color: #cacaca;
    cursor: not-allowed;
  }
</style>
